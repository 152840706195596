var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apps-list"},[_c('t-row',{attrs:{"gap":_vm.cardsGap}},_vm._l((_vm.apps),function(app){return _c('t-col',{key:app.id,attrs:{"cols":6,"tablet":_vm.tabletCols,"mobile":12}},[_c('ui-link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"router-link__no-style apps-list__card-link",attrs:{"to":{
              name: 'app',
              params: {
                category: _vm.getCategoryBySlug(app.categorySlug, true),
                app: app.slug
              }
            }}},[_c('app-card',{staticClass:"apps-list__card",attrs:{"title":app.title,"description":app.shortDescription,"icon":app.icon,"is-ads":app.isAds,"is-trending":app.isTrending,"is-new":app.isNew,"is-verified":app.isVerified}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }