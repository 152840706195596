<template>
  <section class="apps">
    <t-container>
      <t-row>
        <t-col :cols="3" mobile="hidden" tablet="hidden">
          <navigation v-if="!isMobile && !isTablet"></navigation>
        </t-col>
        <t-col :cols="9" :tablet="12" :mobile="12">
          <div class="apps__content">
            <banner v-if="!isAppPage"></banner>
            <div class="apps__search-filter-wrapper">
              <search-field
                  :search-value="searchValue"
                  @search-input-changed="setSearchValue"
                  class="apps__search"
                  :style="{ 'margin-bottom': $route.name === 'app' || isMobile || isTablet ? '0' : '20px' }"
                  :in-app="$route.name === 'app'"
              ></search-field>
              <t-selector
                  :selected-item="currentFilter"
                  height="inherit"
                  :items="appsFilters"
                  @select="updateFilter"
                  v-if="(!isMobile && !isTablet) && !isAppPage"
              ></t-selector>
            </div>
            <router-view v-if="$route.name === 'app'"></router-view>
            <!-- Current filter can be mutated from apps-list component -->
            <list-wrapper
                v-else
                :search-value.sync="searchValue"
                :is-category-changing="isCategoryChanging"
                @clear="clearSearchValue"
            ></list-wrapper>
          </div>
        </t-col>
      </t-row>
    </t-container>
  </section>
</template>

<script>
import SearchField from "./Navigation/SearchField.vue";
import Navigation from "~/components/apps/Navigation/Navigation.vue";
import Banner from "~/components/apps/Banner.vue";
import ListWrapper from "~/components/apps/List/ListWrapper.vue";
import IconAppsWallets from "@img/icons/tonscan/apps/wallets.svg?vue";
import IconAppsExchanges from "@img/icons/tonscan/apps/exchanges.svg?vue";
import IconAppsMarketplaces from "@img/icons/tonscan/apps/marketplaces.svg?vue";
import IconAppsGames from "@img/icons/tonscan/apps/games.svg?vue";
import IconAppsStaking from "@img/icons/tonscan/apps/staking.svg?vue";
import IconAppsSocial from "@img/icons/tonscan/apps/social.svg?vue";
import IconAppsTools from "@img/icons/tonscan/apps/tools.svg?vue";
import IconAppsOther from "@img/icons/tonscan/apps/other.svg?vue";
import IconAppsAll from "@img/icons/tonscan/apps/all.svg?vue";
import {TONSCAN_APPS_ENDPOINT} from "~/config";

export default {
  name: "PageApps",
  components: {
    ListWrapper,
    Navigation,
    SearchField,
    Banner,
  },
  metaInfo() {
    return {
      title: `TON Explorer :: ${this.getCategoryBySlug(this.currentCategory)}`
    }
  },
  provide() {
    return {
      getCurrentCategory: () => this.currentCategory,
      setCurrentCategory: (category) => this.setCurrentCategory(category),

      // Filters and categories can be set only in PageApps component.
      // No need to mutate them from child components
      getAppsFilters: () => this.appsFilters,
      getCategories: () => this.categories,


      getCurrentFilter: () => this.currentFilter,
      setCurrentFilter: (filter) => this.currentFilter = filter,

      getCategoryBySlug: this.getCategoryBySlug,
      getAppsCards: this.getAppsCards,
      getToByCategory: this.getToByCategory
    }
  },
  data() {
    return {
      currentCategory: 'apps',
      currentFilter: null,
      searchValue: '',
      isCategoryChanging: false
    }
  },
  computed: {
    // Banner and filter selector visibility depends on isAppPage field
    isAppPage() {
      return this.$route.name === 'app'
    },
    // Filters and categories are computed properties
    // because names must reactively change on language change
    appsFilters() {
      return [
        {
          name: this.$t('apps.filters.top'),
          value: 'popular'
        },
        {
          name: this.$t('apps.filters.newest'),
          value: 'newest'
        }
      ]
    },
    categories() {
      return [
        {
          name: this.$t('apps.categories.all'),
          routeName: 'apps',
          categorySlug: null,
          icon: IconAppsAll
        },
        {
          name: this.$t('apps.categories.wallets'),
          routeName: 'wallets',
          categorySlug: 'wallet',
          icon: IconAppsWallets
        },
        {
          name: this.$t('apps.categories.exchanges'),
          routeName: 'exchanges',
          categorySlug: 'exchange',
          icon: IconAppsExchanges
        },
        {
          name: this.$t('apps.categories.marketplaces'),
          routeName: 'marketplaces',
          categorySlug: 'marketplace',
          icon: IconAppsMarketplaces
        },
        {
          name: this.$t('apps.categories.games'),
          routeName: 'games',
          categorySlug: 'game',
          icon: IconAppsGames
        },
        {
          name: this.$t('apps.categories.staking'),
          routeName: 'staking',
          categorySlug: 'staking',
          icon: IconAppsStaking
        },
        {
          name: this.$t('apps.categories.social'),
          routeName: 'social',
          categorySlug: 'social',
          icon: IconAppsSocial
        },
        {
          name: this.$t('apps.categories.tools'),
          routeName: 'tools',
          categorySlug: 'tool',
          icon: IconAppsTools
        },
        {
          name: this.$t('apps.categories.other'),
          routeName: 'other',
          categorySlug: 'other',
          icon: IconAppsOther
        },
      ]
    }
  },
  methods: {
    updateFilter(filter) {
      this.currentFilter = filter
    },
    clearSearchValue() {
      this.searchValue = ''
    },
    setCurrentCategory(category) {
      console.log('from: ', this.currentCategory, 'to: ', category)
      if (category === this.currentCategory) {
        return
      }
      this.currentCategory = category
    },
    setSearchValue(value) {
      console.log('execute setSearchValue')
      // this.isCategoryChanging = true;
      console.log(value)
      this.searchValue = value;
      if (this.isAppPage) {
        this.$router.push({name: 'apps'})
      }
      this.setCurrentCategory('apps');
      // this.isCategoryChanging = true;
    },
    getCategoryBySlug(categorySlug, nav = false) {
      for (const category of this.categories) {
        if (category.categorySlug === categorySlug) {
          if (nav) {
            return category.routeName;
          } else {
            return category.name;
          }
        }
      }

      // Tmp solution
      // Right now DEXs and exchanges are in same category
      return "exchanges";
    },
    getToByCategory(category, isSlug = false) {
      if (isSlug) {
        category = this.categories.find((item) => item.categorySlug === category).routeName
      }
      return category === 'apps' ? { name: 'apps' } : { name: 'apps-category', params: { category } }
    },
    getAppsCards(responseHits) {

      return responseHits.map((item) => {
          item = item.document

          const app = {
            title: item.name,
            icon: `${TONSCAN_APPS_ENDPOINT}${item.avatar}`,
          }

          if (!item.avatar) {
            app.icon = undefined;
          }

          if (item.is_new) {
            app.chip = 'new'
          }

          if (item.is_trending) {
            app.chip = 'hot'
          }

          app.slug = item.slug
          app.id = item.id
          app.isAds = item.is_ads
          app.isTrending = item.is_trending
          app.isNew = item.is_new
          app.isVerified = item.is_verified
          app.categorySlug = item.category_slug

          app.shortDescriptionEn = item.short_description
          app.shortDescriptionRu = item.short_description_ru

          if (this.appLocale === 'ru') {
            app.shortDescription = app.shortDescriptionRu;
          } else {
            app.shortDescription = app.shortDescriptionEn;
          }
          return app
        })
    },
  },
  watch: {
    '$route': {
      handler(to, from) {
        if (to.name === 'app') {
          this.searchValue = '';
        }
        if (to.name === 'apps') {
          this.currentCategory = 'apps'
        }
      },
      deep: true
    },
  },
  beforeMount() {
    this.currentFilter = this.appsFilters[0].value
    this.currentCategory = this.$route.params.category || 'apps'
  }
};
</script>

<style lang="scss" scoped>

  .apps {
    // font-weight: 500;
    margin-top: 2px;

    &__content {
      padding-left: 20px;
    }

    &__search-filter-wrapper {
      display: flex;
      gap: 15px;
    }

    &__search {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1240px) {
    .apps {
      &__content {
        padding: 0 8px;
        border-radius: 0;
      }
    }
  }

</style>
