<template>
  <div style="margin: 20px 0;">
    <div class="app__description">
      <div
          class="app__description-container"
          :class="{
            'app__description-container--expanded': descriptionExpanded,
            'app__description-container--blur': !descriptionExpanded && canExpand
          }"
          ref="descriptionContainer"
          v-show="!loading"
          v-html="descriptionMd"
      ></div>
      <t-skeleton-loader
          v-if="loading"
          type="rectangle"
          width="100%"
          height="115px"
          border-radius="24px"
          :class="{ 'app__description--loading': loading }"
      ></t-skeleton-loader>
    </div>
    <div class="app__description-expander"
         v-if="!descriptionExpanded && canExpand && !loading"
         @click="expandDescription"
    >
      {{ $t('apps.app.show_more') }}
    </div>
  </div>
</template>

<script>
import {marked} from "marked";

export default {
  name: "AppDescription",
  props: {
    description: {
      type: null,
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      descriptionExpanded: false,
      canExpand: false
    }
  },
  computed: {
    descriptionMd() {
      return this.description ? marked(this.description) : null
    }
  },
  methods: {
    expandDescription() {
      this.descriptionExpanded = true;
      this.canExpand = false;
    }
  },
  watch: {
    descriptionMd() {
      if (!this.loading) {
        this.$nextTick(() => {
          if (this.$refs.descriptionContainer.scrollHeight > 244) {
            this.canExpand = true;
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

    $line-height: 24px;

    .app__description {
      max-width: 580px;
      font-weight: 400;
      line-height: $line-height;
      color: var(--body-text-color);

      &--loading {
        width: 100%;
        min-height: 115px;
        margin-top: 24px;
      }

      &-container {
        // Truncated description must contain 10 lines
        max-height: $line-height * 10 + 4;
        overflow: hidden;
        position: relative;

        &--blur::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 32px;
          background: linear-gradient(to top, var(--card-background), transparent);
          pointer-events: none; /* so the gradient doesn't block clickable elements */
        }

        &--expanded {
          max-height: none;
          margin-bottom: -16px;
        }
      }

      &-expander {
        margin-top: 12px;
        font-weight: 500;
        color: var(--blue-bright);
        cursor: pointer;
      }
    }

</style>
