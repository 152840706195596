<template>
  <section class="apps-list">
    <navigation-mobile
      v-if="isMobile || isTablet"
      @filter-update="updateFilter"
      @category-change="handleCategoryChange"
    ></navigation-mobile>
    <list v-show="!loading" :apps="apps" :loading="loading"></list>
    <list-skeleton v-if="loading"></list-skeleton>
    <list-skeleton v-if="loadingMoreApps"></list-skeleton>
    <search-not-found v-if="!loading && apps.length === 0" class="app-list__not-found"></search-not-found>
  </section>
</template>

<script>
import AppCard from "~/components/apps/List/AppCard.vue";
import {searchApps} from "~/api/typesense";
import {mapState} from "vuex";
import SearchNotFound from "~/components/apps/Navigation/SearchNotFound.vue";
import ListSkeleton from "~/components/apps/List/ListSkeleton.vue";
import NavigationMobile from "~/components/apps/Navigation/NavigationMobile.vue";
import List from "~/components/apps/List/List.vue";

export default {
  name: "ListWrapper",
  components: {
    NavigationMobile,
    ListSkeleton,
    SearchNotFound,
    AppCard,
    List
  },
  inject: [
    'getCurrentCategory',
    'getCurrentFilter',
    'setCurrentFilter',
    'getCategories',
    'getAppsCards'
  ],
  props: {
    searchValue: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      appsPerPage: 20,
      loading: true,
      loadingMoreApps: false,
      page: 1,
      apps: [],
      found: 0,
      isCategoryChanging: false,
      windowInnerWidth: window.innerWidth,
      bottomScrollInterval: null
    }
  },
  computed: {
    ...mapState({
      appLocale: state => state.appLocale
    }),
    categories() {
      return this.getCategories();
    },
    currentFilter() {
      return this.getCurrentFilter()
    },
    currentCategory() {
      return this.getCurrentCategory();
    }
  },
  methods: {
    async handleCategoryChange() {
      if (this.currentCategory === 'apps' && this.searchValue) {
        return
      }
      if (this.currentCategory !== 'apps' && this.searchValue) {
        this.$emit('clear');
      }
      this.$nextTick(async () => {
        await this.resetApps()
      })
    },
    async updateFilter(filter) {
      this.setCurrentFilter(filter);
      await this.resetApps();
    },
    getCategorySlugByRouteName(routeName) {
      return this.categories.find((route) => route.routeName === routeName).categorySlug
    },
    isAtBottom() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const windowSize = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      return Math.ceil(scrollPosition + windowSize) >= documentHeight;
    },
    async getAppsWithCategoryAndFilter() {

      // Default request params
      let paramsObj = {
        per_page: this.appsPerPage,
        query_by: 'name,tags,category_slug',
        page: this.page
      }

      // Set filters
      const filter = this.getCurrentFilter();
      if (filter === 'popular') {
        paramsObj.sort_by = 'weight:DESC';
      } else if (filter === 'newest') {
        paramsObj.sort_by = 'created_at:DESC';
      }

      // If search value not empty - search in all categories
      if (this.searchValue) {
        paramsObj.q = this.searchValue
        return await this.getApps(paramsObj);
      }

      // If category selected - get apps by category
      if (this.currentCategory !== 'apps') {
        paramsObj.filter_by = `category_slug:${ this.getCategorySlugByRouteName(this.currentCategory) } && weight:>0`;
        paramsObj.q = '*';
        paramsObj.query_by = 'category_slug';

        // DEXs and exchanges are in one category
        if (this.currentCategory === 'exchanges') {
          paramsObj.filter_by = 'category_slug:[exchange, dex] && weight:>0';
        }

        return await this.getApps(paramsObj);
      }

      // If category not selected - get all apps
      paramsObj.q = ''
      paramsObj.filter_by = 'weight:>0'
      return await this.getApps(paramsObj);
    },
    async resetApps() {
      this.page = 1;
      this.loading = true;
      this.apps = await this.getAppsWithCategoryAndFilter()
      this.loading = false;
    },
    handleScroll() {
      const nearBottom =
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 20;
      if (nearBottom) {
        if (!this.loadingMoreApps) {
          this.loadMoreApps()
        }
      }
    },
    async loadMoreApps() {
      if (this.loadingMoreApps === true) {
        return
      }
      if (this.found <= this.appsPerPage * this.page) {
        return
      }
      this.loadingMoreApps = true;
      this.page++;
      let moreApps = await this.getAppsWithCategoryAndFilter();

      this.apps.push(...moreApps);
      this.loadingMoreApps = false;
    },
    async getApps(params) {
      const res = await searchApps(params);
      this.found = res.found;
      const hits = res.hits;
      return this.getAppsCards(hits);
    },
    setWindowInnerWidth() {
      this.windowInnerWidth = window.innerWidth
    }
  },
  mounted() {
    // This is solution to handle MacBook and iPhone "elastic scroll" or "pull to refresh"
    // which pulls whole web page, but doesn't trigger any event on 'window' object
    this.bottomScrollInterval = setInterval(() => {
      if (this.isAtBottom() && !this.loading) {
        this.loadMoreApps()
      }
    }, 500)
  },
  async beforeMount() {
    await this.resetApps();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.setWindowInnerWidth);
  },
  beforeDestroy() {
    clearInterval(this.bottomScrollInterval);
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.setWindowInnerWidth);
  },
  watch: {
    async searchValue() {
      await this.resetApps()
    },
    async currentCategory() {
      await this.handleCategoryChange()
    },
    appLocale(newAppLocale) {
      for (const app of this.apps) {
        if (newAppLocale === 'ru') {
          app.shortDescription = app.shortDescriptionRu;
        } else {
          app.shortDescription = app.shortDescriptionEn;
        }
      }
    },
    async currentFilter(filter) {
      await this.updateFilter(filter);
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
