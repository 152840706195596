<template>
  <div class="apps-list">
    <t-row :gap="cardsGap">
      <t-col
          v-for="app in apps"
          :key="app.id"
          :cols="6"
          :tablet="tabletCols"
          :mobile="12"
      >
        <ui-link
            v-show="!loading"
            class="router-link__no-style apps-list__card-link"
            :to="{
                name: 'app',
                params: {
                  category: getCategoryBySlug(app.categorySlug, true),
                  app: app.slug
                }
              }"
        >
          <app-card
              class="apps-list__card"
              :title="app.title"
              :description="app.shortDescription"
              :icon="app.icon"
              :is-ads="app.isAds"
              :is-trending="app.isTrending"
              :is-new="app.isNew"
              :is-verified="app.isVerified"
          >
          </app-card>
        </ui-link>
      </t-col>
    </t-row>
  </div>
</template>

<script>
import AppCard from "~/components/apps/List/AppCard.vue";

export default {
  name: "AppsList",
  components: {
    AppCard
  },
  props: {
    apps: {
      type: Array
    },
    loading: {
      type: Boolean
    }
  },
  inject: ['getCategoryBySlug'],
  computed: {
    cardsGap() {
      return this.isTablet ? 16 : 20
    },
    tabletCols() {
      return 6;
      // return this.windowInnerWidth < 580 ? 12 : 6;
    },
  }
}
</script>

<style lang="scss" scoped>


.apps-list {

  &__card {
    margin-bottom: 20px;
  }

  &__filter-selector {
    display: flex;
    align-items: center;
    position: absolute;
    right: 8px;
  }

  &__not-found {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {

  .t-col:first-child .apps-list__card-link .apps-list__card {
    border-top: 1px solid var(--card-border-color);
  }

  .apps-list {
    margin-top: 12px;
  }

  .apps-list__card {
    margin: 0 -16px;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}

</style>
