<template>
  <div class="app__info">
    <app-info-item :title="$t('apps.app.category')" :loading="loading">
      <ui-link
          :to="{
                name: 'apps-category',
                params: { category: this.getCategoryBySlug(categorySlug, true) }
              }"
      >
        {{ categoryTitle }}
      </ui-link>
    </app-info-item>

    <app-info-item :title="$t('apps.app.website')" :loading="loading">
      <a :href="link" target="_blank">{{ website }}</a>
    </app-info-item>

    <app-info-item :loading="loading" :title="$t('apps.app.blockchain_address')" v-if="address">
      <ui-copy-button class="card-main-address"
        v-bind:successMessage="$t('address.info.copy_success')"
        v-bind:copy="address"
      >
        <ui-address :address="address" :hideName="true" />
      </ui-copy-button>
    </app-info-item>

  </div>
</template>

<script>
import AppInfoItem from "~/components/apps/App/AppInfoItem.vue";

export default {
  name: "Info",
  components: {
    AppInfoItem
  },
  props: {
    address: {
      type: String
    },
    categorySlug: {
      type: String
    },
    link: {
      type: String
    },
    loading: {
      type: Boolean
    },
  },
  inject: ['getCategories', 'getCategoryBySlug'],
  data() {
    return {
      website: null
    }
  },
  computed: {
    categoryTitle() {

      // Tmp solution
      // Right now DEXs and exchanges are in same category
      if (this.categorySlug === 'dex') {
        return this.$t('apps.categories.dexs')
      } else if (this.categorySlug === 'exchange'){
        return this.$t('apps.categories.exchanges')
      }
      return this.$t(`apps.categories.${this.getCategoryBySlug(this.categorySlug, true)}`)
    },
  },
  methods: {
    setInfo() {
      this.setLink();

      if (this.categorySlug === 'dex') {
        this.category = this.$t('apps.categories.dexs')
      } else if (this.categorySlug === 'exchange') {
        this.category = this.$t('apps.categories.exchanges')
      }
    },
    setLink() {

      let link = this.link

      if (!link.includes('https://') && !link.includes('http://')) {
        link = 'https://' + link;
      }

      const linkURL = new URL(link)

      // Telegram links must contain only @name
      if(linkURL.hostname === 't.me') {
        let telegramLink = `@${linkURL.pathname}`
        telegramLink = telegramLink.replace('/', '');
        this.website = telegramLink.split('/')[0]
      } else {
        if (linkURL.pathname === '/') {
          this.website = `${linkURL.hostname}`
        } else {
          // GitHub links must contain path name
          if (linkURL.hostname === 'github.com') {
            this.website = `${linkURL.hostname}${linkURL.pathname}`;
          } else {
            this.website = `${linkURL.hostname}`;
          }
        }
      }
    },
  },
  watch: {
    loading() {
      if (this.loading === false) {
        this.setInfo()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.address-link:hover {
  text-decoration: underline;
}

</style>
