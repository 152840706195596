<template>
  <div class="app__header">
    <div
      v-if="!loading"
      :style="{ 'background-image': `url(${icon})` }"
      class="app__logo"
    ></div>
    <div v-else class="app__logo">
      <t-skeleton-loader
          border-radius="12px"
          type="rectangle"
          width="100%"
          height="100%"
      ></t-skeleton-loader>
    </div>
    <div class="app__heading">
      <h1 class="app__title" v-if="!loading">
        {{ title }}
        <icon-verified v-if="isVerified"></icon-verified>
      </h1>
      <t-skeleton-loader
          v-else
          type="paragraph"
          height="32px"
          width="100%"
          border-radius="32px"
      ></t-skeleton-loader>
      <div class="app__short-description">
        <span v-if="!loading">{{ shortDescription }}</span>
        <t-skeleton-loader
            v-else
            type="paragraph"
            width="100%"
            height="24px"
            border-radius="32px"
        ></t-skeleton-loader>
      </div>
      <div class="app__open-btn" v-if="!isMobile">
        <a target="_blank" :href="link" v-if="!loading">
          <t-btn color="blue">
            {{ $t('apps.app.open_app') }}
          </t-btn>
        </a>
        <t-skeleton-loader
            v-else
            type="button"
            height="40px"
            width="100%"
        ></t-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import IconVerified from "@img/icons/tonscan/apps/verified.svg?vue";

export default {
  name: "Header",
  components: {
    IconVerified
  },
  props: {
    loading: {
      type: Boolean
    },
    title: {
      type: String
    },
    icon: {
      type: String
    },
    isVerified: {
      type: Boolean
    },
    link: {
      type: String
    },
    shortDescription: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>

.app {
  &__header {
    display: flex;
    margin-bottom: 25px;
  }

  &__logo {
    width: 140px;
    height: 140px;
    border-radius: 12px;
    background-size: cover;
    flex-shrink: 0;
  }

  &__heading {
    margin-left: 30px;
  }

  &__title {
    font-size: 24px;
    display: flex;
    gap: 9px;
    align-items: center;
    margin: 0;
    min-height: 24px; // For skeleton

    & svg {
      color: var(--app-icon-verified-color);
    }
  }

  &__short-description {
    margin-top: 8px;
    font-weight: 400;
    color: var(--app-short-description);
    max-width: 420px;
    line-height: 24px;
    // For skeleton loader
    min-width: 180px;
  }

  &__open-btn {
    margin-top: 14px;

    & a {
      display: block;
      width: fit-content;
    }
  }

  &__open-btn button {
    text-transform: uppercase;
    height: 40px;
    font-size: 14px;
  }

  &__open-btn a {
    text-decoration: none;
  }
}

@media screen and (max-width: 480px) {

  .app__open-btn {
    margin-top: 10px;
  }

  .app__logo {
    width: 96px;
    height: 96px;
    border-radius: 24px;
  }

}

</style>
