<template>
  <div class="apps-list__navigation">
    <div
        class="apps-list__nav"
        :class="{ 'apps-list__nav--open': mobileNavigationOpen }"
    >

      <ui-link
          v-if="firstMobileCategory.routeName === 'apps'"
          class="nav-btn__link router-link__no-style"
          :to="{ name: 'apps' }"
      >
        <navigation-mobile-btn
            :category="firstMobileCategory"
            :open="firstMobileCategoryOpen"
            @open="toggleMobileNavigation()"
        ></navigation-mobile-btn>
      </ui-link>
      <navigation-mobile-btn
          v-else
          :category="firstMobileCategory"
          :open="firstMobileCategoryOpen"
          @open="toggleMobileNavigation()"
      ></navigation-mobile-btn>

      <template v-show="mobileNavigationOpen">
        <ui-link
            class="nav-btn__link router-link__no-style"
            v-for="category in categories.slice(1)"
            :key="category.routeName"
            :to="getToByCategory(category.routeName)"
            :style="{
                'visibility': mobileNavigationOpen ? 'visible' : 'hidden',
              }"
        >
          <navigation-mobile-btn
              :category="category"
              :open="currentCategory === category.routeName"
              @open="setCategoryFromMobile"
          ></navigation-mobile-btn>
        </ui-link>
      </template>
    </div>
    <div class="apps-list__filter-selector" v-show="!mobileNavigationOpen">
      <t-selector
          :items="appsFilters"
          :selected-item="currentFilter"
          @select="updateFilter"
      ></t-selector>
    </div>
  </div>
</template>

<script>
import NavigationMobileBtn from "~/components/apps/Navigation/NavigationMobileBtn.vue";

export default {
  name: "AppsListNavigation",
  components: {NavigationMobileBtn},

  inject: [
    'getAppsFilters',
    'getCategories',
    'getCurrentCategory',
    'setCurrentCategory',
    'getCurrentFilter',
    'setCurrentFilter',
    'getToByCategory'
  ],
  data() {
    return {
      mobileNavigationOpen: false,
      currentMobileCategory: null,
      firstMobileCategory: null,
    }
  },
  methods: {
    setCategoryFromMobile(category) {
      this.currentMobileCategory = category;
      this.firstMobileCategory = category;
      this.setCurrentCategory(category.routeName);
      this.mobileNavigationOpen = false;
    },
    toggleMobileNavigation() {
      if (this.firstMobileCategory.routeName === 'apps' && this.mobileNavigationOpen) {
        this.setCategoryFromMobile(this.appsCategory)
        return
      }
      this.firstMobileCategory = this.appsCategory;
      this.mobileNavigationOpen = !this.mobileNavigationOpen;
    },
    updateFilter(filter) {
      this.$emit('filter-update', filter)
      this.setCurrentFilter(filter)
    }
  },
  computed: {
    appsFilters() {
      return this.getAppsFilters()
    },
    appsCategory() {
      return this.categories[0]
    },
    currentCategory() {
      return this.getCurrentCategory()
    },
    currentFilter() {
      return this.getCurrentFilter()
    },
    categories() {
      return this.getCategories();
    },
    currentCategoryRouteName() {
      return this.categories.find((category) => category.routeName === this.currentCategory)
    },
    firstMobileCategoryOpen() {
      if (this.mobileNavigationOpen) {
        return this.currentCategory === 'apps';
      }
      return this.currentCategory !== 'apps';
    },
  },
  watch: {
    '$store.state.appLocale': {
      handler() {
        this.firstMobileCategory = this.appsCategory
        this.currentMobileCategory = this.appsCategory
      }
    },
    currentCategory: {
      async handler() {
        this.$emit('category-change')

        if (this.currentCategory === 'apps') {
          this.firstMobileCategory = this.appsCategory
        } else {
          this.firstMobileCategory = this.currentCategoryRouteName
        }
      },
      deep: true
    },
  },
  beforeMount() {
    const currentCategory = this.currentCategoryRouteName
    this.setCategoryFromMobile(currentCategory)
  }
}
</script>

<style lang="scss" scoped>

  .apps-list {

    &__navigation {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
    }

    &__nav {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: 40px;
      transition-property: max-height;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;

      &--open {
        max-height: 500px;
      }
    }
  }

  @media screen and (max-width: 480px) {

    .apps-list {
      margin-top: 0;

      &__navigation {
        margin: 12px 0 12px 0;
      }
    }
  }

</style>
