<template>
  <div class="search-container">
    <div class="search-container__box" :class="{
      'search-focused': searchFocused,
      'search-container__box--results-visible': this.apps.length !== 0 && searchFocused
    }">
      <icon-search class="search-icon"></icon-search>
      <input
          type="text"
          :placeholder="$t('apps.search')"
          class="search-input"
          @focus="focus"
          @blur="blur"
          @input="searchInputChange"
          v-model="searchInputValue"
      />
      <div class="right-icon">
        <icon-loading v-if="loading"></icon-loading>
        <icon-close @click="clearSearch" v-else-if="this.apps.length !== 0 || searchInputValue !== ''"></icon-close>
      </div>
    </div>
    <div class="search__results" v-if="showSearchResults && inApp" ref="resultsContainer">
      <ui-link
          v-for="app in apps" :key="app.slug"
          :to="{ name: 'app', params: { app: app.slug } }"
          class="router-link__no-style"
          @click.native="selectSearchResult"
      >
        <search-result :app="app"></search-result>
      </ui-link>
    </div>
  </div>
</template>

<script>
import IconSearch from '@img/icons/tonscan/apps/search.svg?vue'
import IconFilters from '@img/icons/tonscan/apps/filters.svg?vue'
import IconClose from  '@img/icons/material-duotone/close.svg?inline'
import IconLoading from '@img/icons/tonscan/loading.svg?vue'
import {searchApps} from "~/api/typesense";
import SearchResult from "~/components/apps/Navigation/SearchResult.vue";

export default {
  components: {
    SearchResult,
    IconSearch,
    IconFilters,
    IconLoading,
    IconClose
  },
  props: {
    inApp: {
      type: Boolean,
      default: false
    },
    searchValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dropdownVisible: false,
      searchFocused: false,
      searchInputValue: '',
      timeout: null,
      searchResults: null,
      apps: [],
      showSearchResults: false,
      loading: false
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    async getSearchResults() {
      console.log(this.searchInputValue);
      let params = {
        per_page: 5,
        query_by: 'name,tags,category_slug',
        page: 1,
        q: this.searchInputValue
      }

      return await searchApps(params);
    },
    selectSearchResult() {
      this.searchFocused = false;
      this.showSearchResults = false;
    },
    blur(event) {
      if (!this.inApp) {
        this.searchFocused = false;
        return;
      }
      if (!this.$refs.resultsContainer.contains(event.relatedTarget)) {
        this.showSearchResults = false;
        this.searchFocused = false;
      }
    },
    focus() {
      this.searchFocused = true;
      this.showSearchResults = true;
    },
    clearSearch() {
      this.searchInputValue = '';
      this.apps = [];
    },
    setSearchResults() {
      this.apps = [];
      for (let app of this.searchResults.hits) {
        app = app.document;
        this.apps.push({
          slug: app.slug,
          shortDescriptionRu: app.short_description_ru,
          shortDescription: app.short_description,
          categorySlug: app.category_slug,
          icon: app.avatar
        })
      }
    },
    async searchInputChange() {

      // Debounce
      clearTimeout(this.timeout);
      if (this.inApp) {
        this.timeout = setTimeout(async () => {
          if (!this.searchInputValue) {
            console.log('empty');
            this.apps = [];
            return
          }
          this.loading = true;
          this.searchResults = await this.getSearchResults()
          this.setSearchResults();
          this.showSearchResults = true;
          this.loading = false;
        }, 500);
      } else {
        this.timeout = setTimeout(() => {this.$emit('search-input-changed', this.searchInputValue)}, 500);
      }
    },
  },
  watch: {
    searchValue() {
      this.searchInputValue = this.searchValue
    },
    '$route.name': {
      handler() {
        this.clearSearch();
        this.searchFocused = false;
        this.showSearchResults = false;
      }
    }
  }
};

</script>

<style lang="scss" scoped>

$search-box-border-size: 2px;

.search-container {
  position: relative;
  width: calc(100% - 4px);

  .search-focused {
    //background: var(--indexpage-search-background-color);
    //border-color: var(--app-search-field-focused-border-color)!important;
    background: var(--indexpage-search-background-color);
  }

  &__box {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(50px - $search-box-border-size * 2);
    color: inherit;
    position: relative;
    border-radius: .5rem;
    background: var(--indexpage-search-background-color);
    

    //background: var(--indexpage-search-background-color);
    border: $search-box-border-size solid var(--card-border-color);

    .search-icon {
      margin: 16px 9px 16px 16px;
      color: var(--app-search-icon);
    }

    .right-icon {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-right: 16px;
    }

    .right-icon:empty {
      width: 0;
    }

    .search-input {
      flex: 1;
      border: none;
      background: transparent;
      color: inherit;
      outline: none;
      font-size: 16px;
      padding: 0;
      height: 100%;

      &::placeholder {
        color: #939394;
      }
      &::-webkit-input-placeholder {
        color: #939394;
      }
    }

    &--results-visible {
      border-radius: 12px 12px 0 0;
    }
  }
}

.search {
  &__results {
    border: 2px solid var(--card-border-color);
    border-top: none;
    position: absolute;
    background: var(--card-background);
    width: 100%;
    z-index: 10000;
    border-radius: 0 0 12px 12px;
  }

  &__results:empty {
    display: none;
  }
}

</style>
