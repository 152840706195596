<template>
  <div class="apps-banner" :style="{ 'background-image': bannerLoaded ? `url(${bannerURL})` : 'none' }">
    <div class="banner__overlay">
      <h2 class="banner__heading" v-html="$t('apps.banner.title')"></h2>
      <div class="banner__actions">
        <a target="_blank" href="https://t.me/SubmitAppBot" class="action__submit">
          <icon-banner-plus></icon-banner-plus>
          <div>{{ $t('apps.banner.submit_app') }}</div>
        </a>
        <a target="_blank" href="https://tonblockchain.ru ">{{ $t('apps.banner.learn_more') }}</a>
      </div>
    </div>
    <t-skeleton-loader
        v-if="!bannerLoaded"
        type="rectangle"
        width="100%"
        height="100%"
        border-radius="inherit"
    ></t-skeleton-loader>
  </div>
</template>

<script>
import IconBannerPlus from "@img/icons/tonscan/apps/banner-plus.svg?vue";

export default {
  name: "AppsBanner",
  components: {
    IconBannerPlus
  },
  data() {
    return {
      bannerLoaded: false,
      bannerURL: require('@img/banner-new.png')
    }
  },
  mounted() {
    const bannerImage = new Image();
    bannerImage.src = this.bannerURL;

    bannerImage.onload = () => {
      this.bannerLoaded = true;
    };
  },
}
</script>

<style lang="scss" scoped>

  .apps-banner {
    position: relative;
    height: 230px;
    margin-bottom: 20px;
    //background-image: url("~/src/img/banner-new.png");
    background-position: center;
    background-size: cover;
    border-radius: .5rem;

    .banner {
      &__heading {
        font-size: 32px;
        text-align: center;
        margin-top: 0;
      }

      &__image {
        width: 100%;
        max-width: 880px;
        border-radius: 12px;
      }

      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
      }

      &__actions {
        display: flex;
        gap: 32px;
        font-weight: 500;

        & > a {
          text-decoration: none;
          color: #FFF;
        }

      .action__submit {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.app-title {
  -webkit-text-fill-color: transparent;
  -webkit-font-feature-settings: "clig" off, "liga" off;
  font-feature-settings: "clig" off, "liga" off;
  background: linear-gradient(89deg, #2b82eb, #1cc8ff);
  background-clip: text;
  -webkit-background-clip: text;
  position: relative;
}

@media screen and (max-width: 480px) {
  .apps-banner {
      height: 240px;
      margin: -12px -16px 12px -16px;
      //background-image: url("~/src/img/banner-mobile.png");
      border-radius: 0;

      .banner {

        &__heading {
          //text-align: left;
          font-size: 24px;
        }

        &__image {
          border-radius: 0;
          height: 240px;
        }

        &__overlay {
          display: flex;
          //align-items: start;
          //margin-left: 28px;
        }
    }
  }
}

@media screen and (max-width: 1240px) and (min-width: 481px) {
  .apps-banner {
      margin: -16px -16px 20px -16px;
      border-radius: 0;
  }
}

</style>
