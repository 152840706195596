<template>
  <div class="app__screenshots-container">
    <div class="app__screenshots" v-if="loading">
      <div
          class="app__screenshot-wrapper"
          v-for="index in 4"
          :key="index"
      >
        <div class="app__screenshot app__screenshot--portrait">
          <t-skeleton-loader
              type="rectangle"
              width="inherit"
              height="100%"
          >
          </t-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="app__screenshots" v-else-if="screenshots.length !== 0">
      <div
          class="app__screenshot-wrapper"
          v-for="(screenshot, index) in screenshots"
          :key="index"
      >
        <div
            class="app__screenshot"
            :class="{
                'app__screenshot--portrait': screenshot.orientation === 'portrait',
                'app__screenshot--landscape': screenshot.orientation === 'landscape',
              }"
            :style="{
                'background-image': `url(${screenshot.url})`,
              }"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppScreenshots",
  props: {
    loading: {
      type: Boolean
    },
    screenshots: {
      type: Array
    },
  }
}
</script>

<style lang="scss" scoped>

  .app__screenshots {
    margin-right: -25px;
    margin-bottom: 32px;
    gap: 16px;
    overflow: auto;
    white-space: nowrap;
    display: flex;
    padding-right: 25px;
    scrollbar-width: none;
    padding-top: 25px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .app__screenshot {
    border-radius: 12px;
    background-size: cover;

    &--portrait {
      width: 210px;
      height: 372px;
    }

    &--landscape {
      width: 659px;
      height: 372px;
    }

    div {
      border-radius: 12px;
    }
  }

  @media screen and (max-width: 480px) {

    .app__screenshots {
      margin-right: -16px;
      padding-right: 16px;
    }
  }


</style>
