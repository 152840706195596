<template>
  <section class="app">
      <app-header
      :loading="loading"
      :is-verified="isVerified"
      :short-description="shortDescription"
      :link="linkFull"
      :icon="icon"
      :title="title"
      ></app-header>
      <div class="app__open-btn--wide" v-if="isMobile && !loading">
      <a target="_blank" :href="linkFull" v-if="!loading">
          <t-btn :to="link" block color="blue">
              {{ $t('apps.app.open_app') }}
          </t-btn>
      </a>
      </div>
      <div class="app__open-btn--wide" v-else-if="loading && isMobile">
      <t-skeleton-loader
          type="button"
          height="40px"
          width="100%"
      ></t-skeleton-loader>
      </div>
      <t-delimiter class="app__delimiter" v-if="description"></t-delimiter>
      <app-description :description="description" :loading="loading"></app-description>
      <t-delimiter class="app__delimiter" v-if="screenshots.length !== 0"></t-delimiter>
      <app-screenshots :loading="loading" :screenshots="screenshots"></app-screenshots>
      <t-delimiter class="app__delimiter"></t-delimiter>
      <app-info :loading="loading" :link="linkFull" :categorySlug="categorySlug" :address="addressValue"></app-info>
  </section>
</template>

<script>
import AppSearchField from "~/components/apps/Navigation/SearchField.vue";
import {findOneApp} from "~/api/typesense";
import { TONSCAN_APPS_ENDPOINT } from '~/config';
import AppDescription from "~/components/apps/App/AppDescription.vue";
import AppHeader from "~/components/apps/App/AppHeader.vue";
import AppScreenshots from "~/components/apps/App/AppScreenshots.vue";
import AppInfo from "~/components/apps/App/AppInfo.vue";
import {mapState} from "vuex";

export default {
name: "App",
components: {
  AppInfo,
  AppScreenshots,
  AppHeader,
  AppDescription,
  AppSearchField,
},
inject: [
  'setCurrentCategory',
  'getCategories'
],
metaInfo() {
  return {
  title: `TON Explorer :: ${this.title || this.$t('apps.title')}`
  }
},
props: {
  app: {
  type: String,
  required: true
  }
},
data() {
  return {
  loading: true,
  title: null,
  icon: null,
  shortDescriptionRu: null,
  shortDescriptionEn: null,
  descriptionRu: null,
  descriptionEn: null,
  screenshots: [],
  categorySlug: null,
  link: null,
  linkFull: null,
  addressValue: null,
  isVerified: false,
  }
},
methods: {
  async getApp() {
  this.loading = true;
  let app = await findOneApp(this.app)
  if (!app.hits[0]) return
  app = app.hits[0].document;
  this.setApp(app);
  this.loading = false;

  // Emit category for similar apps
  this.$emit('loaded', this.categorySlug, this.title);
  },
  setApp(app) {
  this.title = app.name
  this.icon = `${TONSCAN_APPS_ENDPOINT}${app.avatar}`
  this.screenshots = app.screenshots.map((screenshot) => {
      const screenshotURL = `${TONSCAN_APPS_ENDPOINT}${screenshot}`

      // Trick to get image orientation without any library
      const img = new Image()
      let orientation = 'portrait'
      img.src = screenshotURL;
      img.onload = () => {
      orientation = img.width > img.height ? 'landscape' : 'portrait';
      }

      return {
      url: screenshotURL,
      orientation
      }
  })

  this.linkFull = app.link

  this.isVerified = app.is_verified;

  this.descriptionRu = app.full_description_ru;
  this.shortDescriptionRu = app.short_description_ru;
  this.descriptionEn = app.full_description;
  this.shortDescriptionEn = app.short_description;

  this.addressValue = app.address;
  this.categorySlug = app.category_slug
  },

},
computed: {
  ...mapState({
  appLocale: state => state.appLocale
  }),
  categories() {
  return this.getCategories();
  },
  // Now only 2 languages present.
  // When there will be 3 or more, this code must be refactored
  description() {
  return this.appLocale === 'ru'
      ? this.descriptionRu
      : this.descriptionEn;
  },
  shortDescription() {
  return this.appLocale === 'ru'
      ? this.shortDescriptionRu
      : this.shortDescriptionEn;
  }
},
async beforeMount() {
  await this.getApp();
},
watch: {
  '$route': {
  async handler(to, from) {
      await this.getApp();
  },
  deep: true
  },
},
}
</script>

<style lang="scss" scoped>

$app-page-padding-x: 25px;
$app-page-padding-y: 25px;

.app {
  padding:
      $app-page-padding-y
      $app-page-padding-x
      0
      $app-page-padding-x;
  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  border-radius: .5rem;
  margin-top: 16px;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;

  &__delimiter {
  margin: 32px -$app-page-padding-x 0 -$app-page-padding-x;
  }

  &__info {
  margin: 32px 0;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-right: 40px;
  gap: 50px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
      display: none;
  }
  }

  &__open-btn--wide {
  margin-top: 24px;
  margin-bottom: 20px;

  & button {
      text-transform: uppercase;
  }
  }
}

@media screen and (max-width: 480px) {

  $app-page-mobile-padding-x: 16px;
  $app-page-mobile-padding-y: 24px;

  .app {
  padding:
      $app-page-mobile-padding-y
      $app-page-mobile-padding-x
      0
      $app-page-mobile-padding-x;
  border: none;
  border-radius: 0;
  margin-left: -$app-page-mobile-padding-x;
  margin-top: 12px;
  width: 100%;
  border-top: 1px solid var(--card-border-color);
  border-bottom: 1px solid var(--card-border-color);

  &__delimiter {
      margin: $app-page-mobile-padding-y -16px $app-page-mobile-padding-y -16px;
  }



  &__screenshots {
      padding-right: 16px;
  }

  }
}
</style>
