<template>
  <div class="app-result">
    <div class="app-result__icon">
      <img v-show="!loading && !imageError" @load="loading = false" :src="iconURL" @error="imageError = true" alt="App icon">
      <icon-broken-image v-if="imageError && !loading" class="broken-icon"></icon-broken-image>
      <t-skeleton-loader v-else type="rectangle" width="inherit" height="inherit" border-radius="inherit"></t-skeleton-loader>
    </div>
    <div class="app-result__info">
      <div class="app-result__name">{{ app.name }}</div>
      <div class="app-result__short-description">{{ app.shortDescription }}</div>
    </div>
  </div>
</template>

<script>
import {TONSCAN_APPS_ENDPOINT} from "~/config";
import IconBrokenImage from "@img/icons/tonscan/image-broken.svg?vue";

export default {
  name: "SearchResult",
  components: {
    IconBrokenImage
  },
  props: {
    app: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      imageError: false
    }
  },
  computed: {
    iconURL() {
      return `${TONSCAN_APPS_ENDPOINT}${this.app.icon}`
    },
    shortDescription() {
      return this.$store.state.appLocale === 'ru' ? app.shortDescriptionRu : app.shortDescription
    }
  }
}
</script>

<style lang="scss" scoped>

.app-result {
  display: flex;
  transition: 0.1s ease-in-out;
  gap: 16px;
  cursor: pointer;
  padding: 12px 16px;
  line-height: 1.4;
  color: inherit;
  background: transparent;
  border-radius: 8px;
  margin: 4px 4px -4px 6px;

  &:hover {
    background: var(--body-light-muted-color);
  }

  &__icon {
    border-radius: 16px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-icon-background);

    & img {
      width: 42px;
      height: 42px;
      border-radius: 8px;
    }

      & svg {
        color: #959a9e;
        width: 75%;
        height: 75%;
      }
  }

  &__name {
    font-weight: 500;
    font-size: 15px;
  }

  &__info {

  }

  &__short-description {
    font-weight: 400;
    font-size: 13px;
    color: var(--app-card-description);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

</style>
