<template>
  <div class="t-col" :style="colStyle">
    <slot></slot>
  </div>
</template>

<script>

const screenSizePropValidator = (value) => {
  return (value > 0 && value <= 12) || value === 'hidden';
}

export default {
  name: "TCol",
  props: {
    /**
     * 'cols' prop stands for desktop screen size
     * 'cols' prop is a fallback if mobile prop or tablet prop are not passed
     * */
    cols: {
      type: [Number, String],
      required: true,
      default: 12,
      validator: screenSizePropValidator,
    },
    mobile: {
      type: [Number, String],
      default: 12,
      required: false,
      validator: screenSizePropValidator,
    },
    tablet: {
      type: [Number, String],
      default: 12,
      required: false,
      validator: screenSizePropValidator
    },
  },
  inject: ['gap'],
  data() {
    return {
      colsActive: this.cols,
    }
  },
  computed: {
    colStyle() {

      const mobileHidden = this.mobile === 'hidden' && this.isMobile
      const tabletHidden = this.tablet === 'hidden' && this.isTablet
      const desktopHidden = this.cols === 'hidden' && (!this.isMobile && !this.isTablet)

      // Hide col on certain screen size
      if (mobileHidden || tabletHidden || desktopHidden) {
        return {
          display: 'none'
        };
      }

      this.handleMediaQueries()

      const widthPercentage = (this.colsActive / 12) * 100;
      return {
        flex: `0 0 ${widthPercentage}%`,
        maxWidth: `${widthPercentage}%`,
        paddingLeft: `${this.gap / 2}px`,
        paddingRight: `${this.gap / 2}px`
      };
    }
  },
  methods: {
    handleMediaQueries() {
      if (this.isMobile) {
        this.colsActive = this.mobile;
      } else if (this.isTablet) {
        this.colsActive = this.tablet;
      } else {
        this.colsActive = this.cols;
      }
    }
  },
  mounted() {
    this.handleMediaQueries();
  }


}
</script>

<style lang="scss" scoped>

.t-col {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  min-height: 1px; // Prevent collapsing
  padding-right: 15px; // Half gutter
  padding-left: 15px; // Half gutter

}
</style>
