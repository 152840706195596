<template>
  <div class="nav-btn" @click="$emit('open', category)" :class="{ 'nav-btn--open': open }">
    <component :is="category.icon" class="nav-btn__icon"></component>
    <div class="nav-btn__text">
      {{ category.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBtn",
  props: {
    category: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>

.nav-btn {
  user-select: none;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: var(--apps-navigation-btn-background);
  padding: 10px 15px 10px 12px;
  border-radius: .5rem;
  font-weight: 500;
  color: var(--app-list-nav-btn-text);
  border: 1px solid var(--apps-navigation-btn-border-color);
  max-height: 40px;
  min-height: 40px;

  &__link {
    display: block;
    max-height: 40px;
  }

  &:active {
    background: var(--apps-navigation-btn-active-background-color);
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: inherit;
  }

  &__text {
    font-size: 14px;
    margin-left: 8px;
    line-height: 20px;
    //padding-top: 1px;
    color: inherit;
  }

  &--open {
    border: 2px solid var(--apps-navigation-btn-selected-border-color);
    padding: 10px 14px 10px 11px;
    background: var(--apps-navigation-btn-selected-background);
    color: var(--apps-navigation-btn-selected-text-color);
  }
}

</style>
