<template>
  <div class="app-wrapper">
    <t-breadcrumbs v-if="breadcrumbsLoaded" :items="breadcrumbs"></t-breadcrumbs>
    <t-skeleton-loader v-else type="paragraph" width="150px" height="24px"></t-skeleton-loader>
    <app @loaded="onAppLoad" :app="app"></app>
    <div class="apps-similar">
      <div class="apps-similar__title">{{ $t('apps.app.similar_products') }}</div>
      <t-row :gap="20">
        <t-col
            v-for="(app, index) in similarApps"
            :key="index"
            :cols="6"
            :tablet="6"
            :mobile="12"
        >
          <ui-link
              class="router-link__no-style"
              :to="{
                name: 'app',
                params: {
                    app: app.slug
                  },
                meta: {
                  title: app.title
                }
              }"
          >
            <app-card
                class="apps-list__card"
                :title="app.title"
                :description="app.shortDescription"
                :icon="app.icon"
                :is-ads="app.isAds"
                :is-trending="app.isTrending"
                :is-new="app.isNew"
                :is-verified="app.isVerified"
                :is-loading="loading"
            >
            </app-card>
          </ui-link>
        </t-col>
      </t-row>
    </div>
  </div>
</template>

<script>
import App from "~/components/apps/App/App.vue";
import AppCard from "~/components/apps/List/AppCard.vue";
import {searchApps} from "~/api/typesense";
export default {
  name: "AppsAppWrapper",
  components: {
    AppCard,
    App
  },
  props: {
    app: {
      type: String,
      required: true
    },
    categoryFrom: {
      type: String,
      required: false
    }
  },
  inject: ['getAppsCards', 'getCategories', 'getToByCategory'],
  data() {
    return {
      similarApps: [],
      loading: false,
      categorySlug: null,
      breadcrumbsLoaded: false,
      appTitle: '',
      _breadcrumbs: [
        {
          name: "Loading...",
          to: { name: 'apps' }
        }
      ]
    }
  },
  methods: {
    async onAppLoad(categorySlug, title) {
      this.breadcrumbsLoaded = true;

      this.appTitle = title;

      if (categorySlug === 'dex') {
        categorySlug = 'exchange';
      }
      this.categorySlug = categorySlug;

      await this.getSimilarApps();

    },
    async getSimilarApps() {
      this.loading = true;
      // Get top 10 apps from current category
      const similarAppsAll = await searchApps({
        q: `*`,
        query_by: 'category_slug',
        filter_by: `category_slug:${this.categorySlug} && weight:>0`,
        sort_by: 'weight:DESC',
        page: 1,
        per_page: 10
      })

      // Tmp solution to select 2 random apps from 10
      let similarApps = []
      for (const app of similarAppsAll.hits) {
        if (app.document.slug !== this.app) {
          similarApps.push(app)
        }
      }
      const random = Math.floor(Math.random() * (similarApps.length - 2));
      const firstApp = similarApps[0]
      similarApps.shift()
      similarApps = [firstApp, similarApps[random === 0 ? 1 : random - 1]]
      this.similarApps = this.getAppsCards(similarApps)

      this.loading = false;
    },
  },
  computed: {
    currentCategory() {
      return this.getCategories().find((category) => category.categorySlug === this.categorySlug).name
    },
    breadcrumbs() {
      let base = [
        {
          name: this.$t('apps.title'),
          to: { name: 'apps' }
        }
      ];

      if (this.currentCategory) {
        base.push({ name: this.currentCategory, to: this.getToByCategory(this.categorySlug, true) });
      }

      if (this.appTitle) {
        base.push({ name: this.appTitle });
      }

      return base;
    }
  },
  watch: {
    '$store.state.appLocale': {
      handler(newLocale) {
        for (let i = 0; i < this.similarApps.length; i++) {
          if (newLocale === 'ru') {
            this.similarApps[i].shortDescription = this.similarApps[i].shortDescriptionRu
          } else {
            this.similarApps[i].shortDescription = this.similarApps[i].shortDescriptionEn
          }
        }
      }
    },
    '$route.params.app': {
      handler() {
        this.breadcrumbsLoaded = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .app-wrapper {
    padding-top: 16px;
  }

  .apps-similar {
    margin-top: 40px;

    &__title {
      text-transform: uppercase;
      font-size: 18px;
      color: #858585;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    .apps-similar {
      .t-row {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
</style>
