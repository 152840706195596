<template>
  <nav class="left-menu">
    <ui-link
        v-for="(category, index) in categories"
        :key="index"
        class="apps-navigation__item"
        :to="getToByCategory(category.routeName)"
        :class="{ 'apps-navigation__item--active': currentCategory === category.routeName }"
        @click.native="setCurrentCategory(category.routeName)"
    >
      <div class="item__wrapper">
        <component :is="category.icon"></component>
        <div class="item__name">
          {{ category.name }}
        </div>
      </div>
    </ui-link>
  </nav>
</template>

<script>
export default {
  name: "AppsNavigation",
  props: {
    category: {
      type: String,
      required: false
    }
  },
  inject: ['getCategories', 'setCurrentCategory', 'getCurrentCategory', 'getToByCategory'],
  computed: {
    categories() {
      return this.getCategories();
    },
    currentCategory() {
      return this.getCurrentCategory();
    }
  },
  watch: {
    '$route.name': {
      handler() {
        if (this.$route.name === 'app') {
          this.setCurrentCategory(this.$route.params.category)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.left-menu {
  position: sticky;
  top: 72px;
}

.apps-navigation {
  display: flex;
  flex-direction: column;

  &__item {
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 48px;
    color: var(--apps-navigation-inactive-color);
    cursor: pointer;
    transition: 0.1s ease-in-out;
    font-weight: 500;

    &:hover {
      color: var(--apps-navigation-hover-color);
      text-decoration: none;
    }

    &--active {
      color: var(--apps-navigation-icon-active);

      & svg {
        color: var(--apps-navigation-icon-active);
      }
    }

    &--active:hover {
      color: var(--apps-navigation-icon-active);
    }

    .item__wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      .item__name {
        margin-left: 16px;
        line-height: 24px;
      }
    }
  }
}

</style>
