import { render, staticRenderFns } from "./TxRowInlineJetton.vue?vue&type=template&id=0656f998&"
import script from "./TxRowInlineJetton.vue?vue&type=script&lang=js&"
export * from "./TxRowInlineJetton.vue?vue&type=script&lang=js&"
import style0 from "./TxRowInlineJetton.vue?vue&type=style&index=0&id=0656f998&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports