<template>
  <div class="t-breadcrumbs">
    <div
        class="t-breadcrumbs__item"
        v-for="(item, index) in items"
        :key="index"
        :class="{ 't-breadcrumbs__item--selected': isLastBreadCrumb(index)}"
    >
      <ui-link :to="item.to" v-if="item.to">
        {{ item.name }}
      </ui-link>
      <span v-else>
        {{ item.name }}
      </span>

      <icon-arrow-right-breadcrumb v-if="!isLastBreadCrumb(index)"></icon-arrow-right-breadcrumb>
    </div>
  </div>
</template>

<script>
import IconArrowRightBreadcrumb from "@img/icons/tonscan/arrow-right-breadcrumb.svg?vue"

export default {
  name: "TBreadcrumbs",
  components: {
    IconArrowRightBreadcrumb
  },
  props: {
    items: {
      type: Array
    }
  },
  methods: {
    isLastBreadCrumb(index) {
      return index === this.items.length - 1
    }
  }
}
</script>

<style lang="scss" scoped>

.t-breadcrumbs {
  display: flex;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &__item {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--breadcrumbs-text-color);

    a, span {
      color: var(--breadcrumbs-text-color);
      cursor: pointer;
      text-decoration: none;
      -webkit-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }

    a:hover {
      color: var(--body-text-color);
    }

    svg {
      color: var(--breadcrumbs-text-color);
    }

    &--selected span {
      color: var(--body-text-color);
      cursor: default;
    }
  }
}

</style>
