<template>
  <div class="app-card">
    <div class="app-card__icon-wrapper" v-show="!isLoading && imageLoaded">
      <img
          v-show="imageLoaded"
          @load="imageLoaded = true"
          class="app-card__icon"
          :src="icon"
          alt="App icon"
      >
    </div>
    <div class="app-card__icon-wrapper" v-if="icon === undefined && !isLoading">
      <div class="app-card__icon">
        <icon-broken-image class="broken-icon"></icon-broken-image>
      </div>
    </div>
    <div v-else-if="isLoading || !imageLoaded" class="app-card__icon-wrapper">
      <div class="app-card__icon">
        <t-skeleton-loader
            type="rectangle"
            height="100%"
            width="100%"
            border-radius="inherit"
        >
        </t-skeleton-loader>
      </div>
    </div>

    <div class="app-card__content">
      <div class="app-card__heading">
        <div class="app-card__title">
          <span v-if="!isLoading">{{ title }}</span>
          <t-skeleton-loader
              v-else
              type="paragraph"
              height="24px"
              width="100px"
              border-radius="24px"
          >
          </t-skeleton-loader>
        </div>
        <t-chip
            class="app-card__chip"
            :class="`app-card__badge--${badge}`"
            v-if="badge && badge !== 'verified'"
        >
          {{ badge }}
        </t-chip>
        <icon-verified class="app-card__icon-verified" v-else-if="badge === 'verified' && !isLoading"></icon-verified>
      </div>
      <div class="app-card__description">
        <p v-if="!isLoading">{{ description }}</p>
        <t-skeleton-loader
            v-else
            type="paragraph"
            height="24px"
            width="100%"
            border-radius="24px"
        >
        </t-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>

import IconVerified from "@img/icons/tonscan/apps/verified.svg?vue";
import IconBrokenImage from "@img/icons/tonscan/image-broken.svg?vue";

export default {
  name: "AppsAppCard",
  components: {
    IconVerified,
    IconBrokenImage
  },
  props: {
    icon: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    isAds: {
      type: Boolean,
      required: false,
      default: false
    },
    isTrending: {
      type: Boolean,
      required: false,
      default: false
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    },
    isVerified: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      // First is foremost
      badgePriority: ['isAds', 'isTrending', 'isNew', 'isVerified'],
      badge: null,
      imageLoaded: false
    }
  },
  methods: {
    setPriorityBadge() {
      for (const badge of this.badgePriority) {
        if (this[badge]) {
          // Key is a property from backend
          // Value is the prefix of the class
          const classes = {
            'isAds': 'ad',
            'isTrending': 'hot',
            'isNew': 'new',
            'isVerified': 'verified'
          }

          this.badge = classes[badge];
          return
        }
      }
    },
  },
  beforeMount() {
    this.setPriorityBadge();
  }
}
</script>

<style lang="scss" scoped>

  $app-card-min-height: 85px;
  $app-card-border-radius: .5rem;
  $app-card-width: $app-card-min-height;
  $app-card-icon-width: $app-card-min-height;
  $app-card-icon-height: $app-card-min-height;

  .app-card {
    display: flex;
    border-radius: $app-card-border-radius;
    border: 1px solid var(--card-border-color);
    background: var(--card-background);
    cursor: pointer;
    min-height: $app-card-min-height;
    z-index: 1;

    &__title {
      color: var(--body-text-color);
      font-weight: 500;
    }

    &__icon {
      border-radius: .5rem;
      width: $app-card-icon-width;
      height: $app-card-icon-height;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--app-icon-background);

      & svg {
        color: #959a9e;
        width: 75%;
        height: 75%;
      }
    }

    &__icon-wrapper {
      width: $app-card-width;
      flex-shrink: 0;
    }

    &__heading {
      display: flex;
      align-items: center;
    }

    &__content {
      padding: 10px 16px 10px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__description {
      margin-top: 6px;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      line-height: 20px;

      & p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--app-card-description);
        margin: 0;
        // width: 95%;
        // display: block;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // -webkit-line-clamp: 1;
      }
    }

    &__chip {
      margin-left: 6px;
      margin-top: -1px;
    }

    &__icon-verified {
      margin-left: 6px;
      color: var(--app-icon-verified-color);
      transform: translateY(-1px);
    }

    &__badge {
      text-transform: uppercase;

      &--hot {
        background: rgba(223, 137, 80, 0.24);
        color: rgba(223, 137, 80);
      }

      &--new {
        background: var(--app-card-badge-new-background);
        color: #54B893;
      }

      &--ad {
        // Ad chip will be in future
      }
    }
  }

  @media screen and (max-width: 480px) {

    $app-card-border-radius-small-screen: 16px;

    .app-card {
      min-height: 96px;

      &__icon {
        width: 64px;
        height: 64px;
        display: flex;
        border-radius: $app-card-border-radius-small-screen;
      }

      &__icon-wrapper {
        padding: 16px 0 16px 16px;
        width: auto;
      }

      &__content {
        padding: 16px;
      }
    }

  }
</style>
